import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="button-container">
        <a href="mailto:mail@carolinfuss.de" className="button">
          Contact Me
        </a>
      </div>
    </div>
  );
}

export default App;
